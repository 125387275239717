import React from 'react'
import {reduxForm, formValueSelector, FormSection} from 'redux-form'
import {Section, TextField, RadioField} from 'jbc-front/components/Form'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import compose from 'lodash/fp/compose'
import Modal from 'jbc-front/components/CommonModal'
import Button from 'jbc-front/components/Button'
import {connect} from 'react-redux'
import {CardInfo, CardInput} from './CardInput'
import {email} from 'validators'
import api from 'api'
import {getState} from 'utils'
import {actionCreators} from 'actions'
import moment from 'moment'
import {asyncError} from 'store/actions/asyncError'

const formName = 'PaymentMethodChange'
const selector = formValueSelector(formName)

export const paymentMethodTypes = [{label: 'クレジットカード支払い', value: 'credit_card'}]

const PaymentMethodForm = ({submitting, handleSubmit, cardInfo, hideModal, dispatch}) => {
  const changeFrom = moment()
  if (changeFrom.date() <= 1) {
    changeFrom.subtract(1, 'month')
  }
  return (
    <React.Fragment>
      <Modal.Body>
        <FormErrors />
        <Section title="決済方法">
          <RadioField name="payment_method_type" options={paymentMethodTypes} />
          請求書払いをご希望の方はサポート窓口までお問い合わせください。
        </Section>
        <Section title="決済情報">
          <FormSection name="credit_info">
            <LabelMapper name="credit_info" label="決済情報の" />
            <CardInput dispatch={dispatch} formName={formName} />
            {cardInfo && <CardInfo cardInfo={cardInfo} />}
            <TextField name="employee_email" label="メールアドレス" validate={email} required />
            変更された決済情報は{changeFrom.format('YYYY年M月')}利用分（
            {changeFrom.clone().add(1, 'month').format('YYYY年M月')}ご請求）から反映されます。
          </FormSection>
        </Section>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal} disabled={submitting}>
            キャンセル
          </Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            登録する
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </React.Fragment>
  )
}

export default compose(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    onSubmit: async (values, dispatch, {hideModal}) => {
      try {
        const {
          auth: {token},
        } = await getState(dispatch)
        await api.createWithAuth(token).clients.changePaymentMethod(values)
        dispatch(actionCreators.fetchData('payment_info', api.createWithAuth(token).clients.getPaymentInfo()))
      } catch (err) {
        dispatch(asyncError(err))
      }
      hideModal()
    },
  }),
  connect((state) => ({
    cardInfo: selector(state, 'credit_info._card_info'),
  }))
)(PaymentMethodForm)
