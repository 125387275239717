import React from 'react'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {recordDisplay, addDay} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Edit} from 'jbc-front/components/icons'
import {HashLink} from 'react-router-hash-link'
import {Dl, Dd, Dt, Half, Grid, SubPanel, ThirdPanel} from 'employees/show/Parts'
import {genderOptions, handicapTypes} from 'FormFields'
import styles from 'employees/show/Parts.scss'
import ReadMoreReadLess from 'components/ReadMoreReadLess'

const HealthAndWelfarePensionInformation = ({employee, yearStyle, editLink, healthInsuranceType}) => {
  const healthInsurance = employee.health_insurance || {}
  const welfarePensionInsurance = employee.welfare_pension_insurance || {}
  const healthInsuranceEndOn = healthInsurance?.end_on || addDay(employee?.retired_at, 1)
  const welfarePensionInsuranceEndOn = welfarePensionInsurance?.end_on || addDay(employee?.retired_at, 1)
  const dependents = employee.employee_dependents || []
  const welfarePensionInsuranceImage = welfarePensionInsurance.image || {}
  return (
    <Panel>
      <Headding>社会保険</Headding>
      <Item>
        <Grid>
          <Half>
            <SubPanel>
              <HashLink to={`${editLink}#HealthInsuranceInfomation`}>
                <SubPanel.Headding icon={<Edit icon={16} />}>健康保険</SubPanel.Headding>
              </HashLink>
              <Grid className={styles.panelHalf}>
                <Dl>
                  <Dt>加入状況</Dt>
                  <Dd>{recordDisplay.boolean(healthInsurance.joined, '加入', '未加入')}</Dd>
                </Dl>
                <Dl>
                  <Dt>健康保険被保険者整理番号{healthInsuranceType === 'kyokai' && '（年金整理番号）'}</Dt>
                  <Dd>
                    {recordDisplay(healthInsurance.joined && healthInsurance.health_insurance_insured_personnel_number)}
                  </Dd>
                </Dl>
                <Dl>
                  <Dt>資格取得日</Dt>
                  <Dd>{recordDisplay.date(healthInsurance.joined && healthInsurance.start_on, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>資格喪失日</Dt>
                  <Dd>{recordDisplay.date(healthInsurance.joined && healthInsuranceEndOn, yearStyle)}</Dd>
                </Dl>
              </Grid>
            </SubPanel>
          </Half>
          <Half>
            <SubPanel>
              <HashLink to={`${editLink}#WelfarePensionInsuranceInfomation`}>
                <SubPanel.Headding icon={<Edit icon={16} />}>厚生年金保険</SubPanel.Headding>
              </HashLink>
              <Grid className={styles.panelHalf}>
                <Dl>
                  <Dt>加入状況</Dt>
                  <Dd>{recordDisplay.boolean(welfarePensionInsurance.joined, '加入', '未加入')}</Dd>
                </Dl>
                <Dl>
                  <Dt>基礎年金番号</Dt>
                  <Dd>
                    {recordDisplay.numbers(
                      welfarePensionInsurance.joined && welfarePensionInsurance,
                      'basic_pension_number',
                      2
                    )}
                  </Dd>
                </Dl>
                {healthInsuranceType !== 'kyokai' && (
                  <Dl>
                    <Dt>厚生年金保険被保険者整理番号</Dt>
                    <Dd>
                      {recordDisplay(
                        welfarePensionInsurance.joined && welfarePensionInsurance.insured_personnel_number
                      )}
                    </Dd>
                  </Dl>
                )}
                <Dl>
                  <Dt>資格取得日</Dt>
                  <Dd>
                    {recordDisplay.date(welfarePensionInsurance.joined && welfarePensionInsurance.start_on, yearStyle)}
                  </Dd>
                </Dl>
                <Dl>
                  <Dt>資格喪失日</Dt>
                  <Dd>
                    {recordDisplay.date(welfarePensionInsurance.joined && welfarePensionInsuranceEndOn, yearStyle)}
                  </Dd>
                </Dl>
                {welfarePensionInsurance.first_time_join && (
                  <Dl>
                    <Dt>初めて厚生年金保険に加入する</Dt>
                    <Dd>はい</Dd>
                  </Dl>
                )}
                {welfarePensionInsurance.need_detail && (
                  <Dl>
                    <Dt>基礎年金番号が分からない</Dt>
                    <Dd>はい</Dd>
                  </Dl>
                )}
                {welfarePensionInsuranceImage.filename && (
                  <Dl>
                    <Dt>年金手帳または基礎年金番号通知書画像</Dt>
                    <Dd>{recordDisplay.file(welfarePensionInsurance.image)}</Dd>
                  </Dl>
                )}
              </Grid>
            </SubPanel>
          </Half>
        </Grid>
        {!_.isEmpty(dependents) && (
          <SubPanel>
            <HashLink to={`${editLink}#Dependent`}>
              <SubPanel.Headding icon={<Edit icon={16} />}>扶養情報</SubPanel.Headding>
            </HashLink>
            <SubPanel.Item>
              <Grid className={styles.fixedTwoColumns}>
                {dependents.map((dependent, index) => (
                  <ThirdPanel key={dependent.id}>
                    <ThirdPanel.Headding>扶養情報 #{index + 1}</ThirdPanel.Headding>
                    <Grid className={[styles.forHalfGrid]}>
                      <Dl>
                        <Dt>続柄</Dt>
                        <Dd>
                          {dependent.relation_type === 'spouse' ? '配偶者' : recordDisplay(dependent.relation_other)}
                        </Dd>
                      </Dl>
                      <Dl>
                        <Dt>氏名</Dt>
                        <Dd>
                          {recordDisplay.fullName(dependent)}
                          <br />
                          {recordDisplay.fullName(dependent, false, true)}
                        </Dd>
                      </Dl>
                      <Dl>
                        <Dt>性別</Dt>
                        <Dd>{recordDisplay.option(dependent.gender, genderOptions)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>生年月日</Dt>
                        <Dd>{recordDisplay.birthday(dependent, yearStyle)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt className={styles.specialHeight}>住所</Dt>
                        <Dd>
                          {dependent.residence_status === 'same_address' ? '同居' : recordDisplay.address(dependent)}
                        </Dd>
                      </Dl>
                      <Dl>
                        <Dt>電話番号</Dt>
                        <Dd>{recordDisplay.numbers(dependent, 'phone_number', 3)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>被扶養者になった日</Dt>
                        <Dd>{recordDisplay.date(dependent.dependent_from, yearStyle)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>扶養に追加された理由</Dt>
                        <Dd>{recordDisplay(dependent.dependent_reason)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>年間収入（今後1年間見込）</Dt>
                        <Dd>{recordDisplay.yen(dependent.annual_income)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>年間所得（1～12月）</Dt>
                        <Dd>{recordDisplay.yen(dependent.annual_earnings)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>障害者区分</Dt>
                        <Dd>{recordDisplay.option(dependent.handicap_type, handicapTypes)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>障害者区分 詳細情報</Dt>
                        <Dd>
                          <ReadMoreReadLess key={dependent.handicap_detail}>
                            {recordDisplay(dependent.handicap_detail)}
                          </ReadMoreReadLess>
                        </Dd>
                      </Dl>
                      <Dl>
                        <Dt>障害者手帳画像</Dt>
                        <Dd>{recordDisplay.file(dependent.handicap_certificate)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>職業</Dt>
                        <Dd>{recordDisplay(dependent.job)}</Dd>
                      </Dl>
                      <Dl>
                        <Dt>税の扶養対象</Dt>
                        <Dd>{dependent.dependent_tax_law == true ? '対象' : '対象外'}</Dd>
                      </Dl>
                      {dependent.residence_status === 'different_address' && (
                        <Dl>
                          <Dt>国内居住親族への年間送金額</Dt>
                          <Dd>{recordDisplay.yen(dependent.domestic_remittance)}</Dd>
                        </Dl>
                      )}
                      {dependent.residence_status === 'different_and_foreign_address' && (
                        <>
                          <Dl>
                            <Dt>国外居住親族への年間送金額</Dt>
                            <Dd>{recordDisplay.yen(dependent.remittance)}</Dd>
                          </Dl>
                          <Dl>
                            <Dt>非居住者区分</Dt>
                            <Dd>{dependent.is_non_resident == true ? '非居住者' : '対象外'}</Dd>
                          </Dl>
                          {dependent.relation_type !== 'spouse' && (
                            <Dl>
                              <Dt>留学区分</Dt>
                              <Dd>{dependent.is_study_abroad == true ? '留学' : '対象外'}</Dd>
                            </Dl>
                          )}
                        </>
                      )}
                      {dependent.relation_type === 'spouse' && (
                        <Dl>
                          <Dt>外国人区分</Dt>
                          <Dd>
                            {dependent.national_type && (dependent.national_type === 'foreigner' ? '外国人' : '日本人')}
                          </Dd>
                        </Dl>
                      )}
                      {dependent.national_type === 'foreigner' && (
                        <Dl>
                          <Dt>ローマ字氏名</Dt>
                          <Dd>
                            {recordDisplay(dependent.romaji_name)} ({recordDisplay(dependent.romaji_name_kana)})
                          </Dd>
                        </Dl>
                      )}
                      {dependent.relation_type === 'spouse' && (
                        <>
                          <Dl>
                            <Dt>基礎年金番号</Dt>
                            <Dd>{recordDisplay.numbers(dependent, 'basic_pension_number', 2)}</Dd>
                          </Dl>
                        </>
                      )}
                    </Grid>
                  </ThirdPanel>
                ))}
              </Grid>
              <Grid>
                <Dl className={styles.specialDl}>
                  <Dt>従たる給与についての扶養控除等申告書の提出</Dt>
                  <Dd>{employee.is_sub_income_report == true ? '対象' : '対象外'}</Dd>
                </Dl>
              </Grid>
            </SubPanel.Item>
          </SubPanel>
        )}
      </Item>
    </Panel>
  )
}
export default connect((state) => ({
  employee: state.employees.current.data,
  healthInsuranceType: _.get(state.employees.current.data, 'office.health_insurance_type'),
}))(HealthAndWelfarePensionInformation)
